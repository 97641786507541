$(() => {
  $('footer .menu-item-has-children').each((index, element) => {
    $(element).append('<span class="drop-down-helper"></span>');
  });

  $('footer').on('click', '.drop-down-helper', (e) => {
    const $this = $(e.target);
    e.preventDefault();
    $this.closest('li').toggleClass('active-menu-item');
    if (!$this.closest('li').hasClass('active-menu-item')) {
      $this.closest('li').children('.sub-menu').slideUp(600);
    } else {
      $this.closest('li').children('.sub-menu').slideDown(600);
    }
  });

  $(window).on('resize', (e) => {
    $("footer .menu-item").each((index,element)=>{
      if ($(e.target).width() > 992 ){
        $(element).children(".sub-menu").removeAttr("style");
      } else if ($(element).hasClass("active-menu-item")) {
        $(element).children(".sub-menu").show();
      }
    });
  });

});