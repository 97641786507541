$( () => {
	// forms validations
	const formsValidation = ( formElem ) => {
		const formElement = document.querySelectorAll( formElem );
		if ( formElement.length != 0 ) {
			$( formElement ).each( ( ind, ele ) => {
				const inputElements = $( ele ).find(
					'input[aria-required="true"]'
				);
				if ( inputElements.length != 0 ) {
					$( inputElements ).each( ( i, input ) => {
						// hide err message on focus field
						input.addEventListener( 'focusin', ( event ) => {
							hideErrorMessage( input );
						} );
						// validate field when field focus out
						input.addEventListener( 'focusout', ( event ) => {
							let inputValue = event.target.value;
							$( inputElements ).each( ( i, ele ) => {
								if ( $(ele).attr('type') == 'email') {
									if ( $(ele).val() != '' && checkEmailAddress( $(ele).val() ) == false ) {
										showErrorMessage(	ele, 'Email address is not valid.' );
									} else if($(ele).val() == '' && $(ele).parent().find( '.wpcf7-not-valid-tip' ).length ) {
										showErrorMessage(	ele,	'The field is required.'	);
									} else {
										hideErrorMessage( ele );
									}
								} else {
									if ( $(ele).val() != '' && $(ele).parent().find( '.wpcf7-not-valid-tip' ).length ) {
										hideErrorMessage( ele );
									}
								}
							});
							if ( inputValue == '' ) {
								// show error is field values an empty
								showErrorMessage(	input,	'The field is required.'	);
							} else {
								// hide error message is field has a values
								hideErrorMessage( input );
								// check email fields validation
								if ( input.type == 'email' ) {
									if (	checkEmailAddress( inputValue ) == false	) {
										// show error message if email address not valid
										showErrorMessage(	input, 'Email address is not valid.'	);
									} else {
										hideErrorMessage( input );
									}
								}
							}
						});
					});
				}
			});
		}
	};

	// check emails address is validity
	const checkEmailAddress = ( email ) => {
		let reg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return reg.test( email );
	};

	// show error message under input field
	const showErrorMessage = ( field, msg ) => {
		$( field ).parent().find( '.wpcf7-not-valid-tip' ).remove(); // remove previous err message
		let messageElement = `<span class="wpcf7-not-valid-tip" aria-hidden="true">${ msg }</span>`;
		$( field ).parent().append( messageElement ); // append new err
	};

	// Hide all err messages
	const hideErrorMessage = ( field ) => {
		$( field ).parent().find( '.wpcf7-not-valid-tip' ).remove();
	};

	// call forms validation function on ready
	formsValidation( '.wpcf7-form' );
} );
