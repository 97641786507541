$( () => {
	const $body = $( 'body' ),
		$innerMenu = $( '.bs-section--inner-scroll-section' ),
		$header = $( 'header' );

	const innerMenuOffset =
		$innerMenu.length != 0 ? $innerMenu.offset().top : 0;

	const stickyBackground = ( scPos ) => {
		if ( scPos > innerMenuOffset - $header.height() ) {
			$innerMenu.addClass( 'active-bg' );
		} else {
			$innerMenu.removeClass( 'active-bg' );
		}
	};

	const scrollTop = () => {
		const scroll = $( window ).scrollTop();
		if ( scroll >= 60 ) {
			$header.addClass( 'scrolled' );
			$body.addClass( 'header-scrolled' );
		} else {
			$header.removeClass( 'scrolled' );
			$body.removeClass( 'header-scrolled' );
		}

		// sticky menu fix state
		if ( innerMenuOffset != 0 ) {
			stickyBackground( scroll );
		}

		if ( $( '#wpadminbar' ).length ) {
			if ( scroll >= 10 ) {
				$body.addClass( 'enabled-admin-bar-on-scroll' );
			} else {
				$body.removeClass( 'enabled-admin-bar-on-scroll' );
			}
		}

		if (
			$(
				'.bs-section--common-post-page-banner, .bs-section--no-header-transparent'
			).length == 0
		) {
			$header.removeClass( 'shadow-enable' );
		}
	};

	scrollTop();

	$( window ).on( 'load scroll', () => {
		scrollTop();
	} );

	/* Header navigation  */
	const topMenu = $( '#menu-top-menu li' );
	const mainMenu = $( '#mega-menu-main-menu' );

	const headerTopNavigationBehavior = ( deviceWidth ) => {
		if ( deviceWidth.matches ) {
			var topMenuList = '';
			for ( let i = 0; i < topMenu.length; i++ ) {
				topMenuList += $( topMenu[ i ] ).html();
			}

			mainMenu.append(
				'<li class="mobile-top-menu">' + topMenuList + '</li>'
			);
		} else {
			$( '.mobile-top-menu' ).remove();
		}
	};

	const deviceWidth = window.matchMedia( '(max-width: 991px)' );
	headerTopNavigationBehavior( deviceWidth ); // Call listener function at run time
	deviceWidth.addEventListener( 'change', () => {
		headerTopNavigationBehavior( deviceWidth );
	} ); // Attach listener function on state changes

	// get current scroller position
	const getYPosition = () => {
		const top = window.pageYOffset || document.documentElement.scrollTop;
		return top;
	};

	let oldPos = 0; // store scroll location before click
	const megaMenu = $( '.mega-menu-toggle' );
	const headerNav = $( '#header.nav' );
	megaMenu.on( 'click', ( e ) => {
		const topPos = getYPosition();
		if ( $( '#mega-menu-main-menu' ).is( ':visible' ) ) {
			// add styles to body if mega-menu is visible
			oldPos = topPos;
			if ( topPos != 0 ) {
				// add styles to body if current scroll position is not 0
				$( 'body' ).css( {
					position: 'fixed',
					width: '100%',
					top: '-' + topPos + 'px',
					let: '0',
					height: '100%',
					overflow: 'hidden',
				} );
				headerNav.addClass( 'mega-opened' ); // add class for backend styles
			}
		} else {
			$( 'body' ).removeAttr( 'style' ); // remove body styles after close mega-menu
			headerNav.removeClass( 'mega-opened' );
			window.scrollTo( 0, oldPos ); // scroll to same position after body fix
			oldPos = 0; // reset window old scrolled position
		}
	} );

	$( '.top-notification__close' ).on( 'click', () => {
		$( 'body' ).removeClass( 'header-notify' ).addClass( 'closed-notify' );
	} );
} );
