$(() => {

  const debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };
  const headlinePlacement = (sectionClass, helperClass) => {
    $(`${sectionClass}`).each((index, ele) => {
      const div = $(ele).find(`${helperClass}`),
        divContent = $(ele).find(div).clone(),
        tab = $(ele).find(`.bs-tab-slider .bs-slider-content .slick-track .slick-slide-wrapper .bs-column--title-content-wrapper`);
      if (!tab.find('.bs-div--title-wrapper').length) {
        divContent.prependTo(tab);
      }
    })
  };

  const appendTitles = debounce(() => {
    if ($(window).width()) {
      headlinePlacement(
        ".bs-section--home-barrier-free-tab-slider",
        ".bs-div--title-wrapper"
      );
    } else {
      $('.bs-section--home-barrier-free-tab-slider .bs-slider-content .slick-track ').find('.bs-div--title-wrapper').remove();
    }
  }, 20);

  const marginTop  = debounce(() => {
    const winWidth = document.documentElement.clientWidth;
    $.each($(".bs-section--home-barrier-free-tab-slider "), (index, element) => {
      const $this = $(element),
        dotsHeight = $this.find(".bs-slider-content .bs-div--title-wrapper").outerHeight() + 9;
      $.each($this.find(".slick-custom-dots"), (index, ele) => {
        if (winWidth >= 992) {
          $(ele).css("top", dotsHeight + "px");
        } else {
          $(ele).removeAttr("style");
        }
      });
    });
  }, 800);

  const paddingTop = debounce(() => {
    const winWidth = document.documentElement.clientWidth;
    $.each($(".bs-section--home-barrier-free-tab-slider"), (index, element) => {
      const $this = $(element),
        quoteHeight = $this.find(".slick-custom-dots").outerHeight() + 70;
      $.each($this.find(".wp-block-quote"), (index, ele) => {
        if (winWidth >= 992) {
          $(ele).css("padding-top", quoteHeight + "px");
        } else {
          $(ele).removeAttr("style");
        }
      });
    });
  }, 40);

  appendTitles();
  marginTop();
  paddingTop();

  $(window).on("load resize", () => {
    appendTitles();
    marginTop();
    paddingTop();
  });
});