$(() => { 
  $('.bs-counter--progress-indicator').each((index, element) => {
    const $this = $(element),
    bgColor = $(element).parents('section').css('background-color'),
    dataOptions = $this.find('.bs-counter__count span').attr( 'data-options' ),
    counterOptions = JSON.parse( dataOptions ),
    countValue = counterOptions.endVal,
    baseClass = $this.attr('class').match(/bs-counter--base-value\-\d+/gi)[0],
    baseValue = baseClass.split('bs-counter--base-value-')[1],
    percentageValue = (countValue / baseValue) * 100;
    $this.attr('data-percentage', percentageValue);
    $this.find('.bs-counter__prefix, .bs-counter__count , .bs-counter__postfix').wrapAll('<div class="bs-counter__wrapper" />');
    if(bgColor != undefined && countValue != undefined && baseValue != undefined) {
      $(element).attr({'style':`--counterBgcolor:${bgColor}; --counterCountValue: ${countValue}; --counterBaseValue: ${baseValue}; `});
      $this.find('.bs-counter__wrapper').append(`<div class="bs-counter__circle">
        <div class="mask full">
          <div class="fill"></div>
        </div>
        <div class="mask half">
          <div class="fill"></div>
        </div>
      </div>`).addClass(`value-${percentageValue}`);

      const style = document.createElement('style'),
        transformValue = `${180 * 0.01 * percentageValue}deg`;
      style.innerHTML = `
        @keyframes fill-${percentageValue} {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(0deg+(180*0.01*${percentageValue}));
          }
        }
        .bs-counter--progress-indicator.active .value-${percentageValue} .bs-counter__circle .mask.full,
        .bs-counter--progress-indicator.active .value-${percentageValue} .bs-counter__circle .fill {
          transform: rotate(${ transformValue });
          animation: fill-${percentageValue} ease-in-out 1s;
        }
      `;
      $this.append(style);
    }
  });
});