$( () => {
	const fixSliderWidth = ( className ) => {
		const sliderElements = $( className );
		if ( sliderElements.length != 0 ) {
			sliderElements.each( ( ind, elem ) => {
				const $this = $( elem ),
					$tabs = $this.find( '.bs-slider-tabs' );
				const slidesToShow = $tabs.get( 0 ).slick.options.slidesToShow;
				$tabs.css( { 'min-width': slidesToShow * 100 + 10 + 'px' } );
			} );
		}
	};

	fixSliderWidth( '.bs-section--our-doctors-vertical-tabslider' );
} );
