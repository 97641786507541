$( () => {
	const tabSliderArrows = ( classNames ) => {
		$( classNames ).each( ( index, element ) => {
			const $this = $( element ),
				$tabs = $this.find( '.bs-slider-tabs' ),
				$contents = $this.find( '.bs-slider-content' );
			const slides = $tabs.get( 0 ).slick.options.slidesToShow;

			if ( $tabs.find( '.slick-slide ' ).length > slides ) {
				$contents.removeClass( 'hide' );
			} else {
				$contents.addClass( 'hide' );
			}
		} );
	};
	tabSliderArrows(
		'.bs-section--partners-logo-testimonials-section,.bs-section--our-doctors-tabslider,.bs-section--our-doctors-vertical-tabslider'
	);
} );
