$(() => {
  console.log("test");
  const maxHeight = (element) => {
    const ele = $(element);
    const $this = $(element);
    const maxHeight = $this.height();
    return maxHeight;
  };

  const debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

  const addEqualHeight = debounce(() => {
    const winWidth = document.documentElement.clientWidth;

    const sectionContent = $(".bs-section--home-connect-your").find('.bs-section__image-container');
    if (winWidth > 991) {
      const titleHeight = maxHeight($(".bs-section--home-connect-your").find(".bs-column--content-wrapper"));
      totalTitleHeight = titleHeight + 149.516;
      sectionContent.css("top" , totalTitleHeight + "px");
    }
  }, 10);

  addEqualHeight();

  $(window).on("load resize", () => {
    addEqualHeight();
  });

});
