
$(() => { 
  $('.submit-button').each((index, element) => {
    $(element).wrapAll(`<span class="button-indicator">${$(element).attr('value')}</span>`);
  });

  $('input[name="agree"').change(() => {
    $('.wpcf7-form').toggleClass('not-agreed', this.checked);
  }).change();

});