require( 'slick-carousel-latest' );
( function ( $ ) {
	$( function () {
		$( '.bs-section--add-progress-bar' ).each( ( index, element ) => {
			const $this = $( element );
			let $slider, $sliderParent;
			if ( $this.find( '.bs-tab-slider' ).length ) {
				$slider = $this.find( '.bs-slider-content' );
				$sliderParent = $slider.parent( '.bs-tab-slider' );
			}
			if ( $this.find( '.bs-slider' ).length ) {
				$slider = $this.find( '.slick-slider' );
				$sliderParent = $slider.parent( '.bs-slider' );
			}
			if ( ! $this.find( '.progress-bar-container' ).length ) {
				$sliderParent.after( `
			<div class="progress-bar-container">
				<div class="progress-bar-wrapper">
					<span class="progress-inner"></span>
				</div>
					<span class="progress-bar-slide-count"></span>
			</div>` );
			}

			$sliderParent.each( ( index, ele ) => {
				let uniqSliderId = $( ele ).attr( 'id' );
				$( '#' + uniqSliderId ).on(
					'init reInit afterChange',
					( event, slick ) => {
						let currentSlideNumber =
								( slick.currentSlide
									? slick.currentSlide
									: 0 ) + 1,
							totalSlideCount = slick.slideCount
								? slick.slideCount
								: 0,
							progressBarValue =
								( currentSlideNumber / totalSlideCount ) * 100 +
								'%',
							progressBarSlideCount = $( '#' + uniqSliderId )
								.parents( '.bs-column' )
								.find( '.progress-bar-slide-count' );

						progressBarSlideCount.text(
							currentSlideNumber + '/' + totalSlideCount
						);
						$( '#' + uniqSliderId )
							.parents( '.bs-column' )
							.find( '.progress-inner' )
							.css( {
								width: progressBarValue,
							} );
					}
				);
			} );
		} );

		if ( $( '[data-slick]' ).length ) {
			$( '[data-slick]' ).not( '.slick-initialized' ).slick();
		}

		$.each(
			$( '.bs-slider-tabs.slick-initialized' ).closest( '.bs-section' ),
			( index, item ) => {
				let waypoint = new Waypoint( {
					element: item,
					handler: function () {
						$( this.element )
							.find( '.bs-slider-tabs.slick-initialized' )
							.slick( 'slickGoTo', 0 );
					},
					offset: function () {
						return this.context.innerHeight() + 50;
					},
				} );
			}
		);

		let timer;
		let windowWidth = 0;
		$( window ).on( 'resize', () => {
			clearTimeout( timer );
			timer = setTimeout( function () {
				if ( windowWidth != $( window ).width() ) {
					if ( $( '.bs-tab-slider---default [data-slick]' ).length ) {
						if (
							$(
								'.bs-tab-slider---default [data-slick]'
							).hasClass( 'slick-initialized' )
						) {
							$(
								'.bs-tab-slider---default [data-slick]'
							)[ 0 ].slick.refresh();
							$( '.bs-tab-slider---default [data-slick]' ).slick(
								'slickGoTo',
								0
							);
						} else {
							$( '.bs-tab-slider---default [data-slick]' )
								.not( '.slick-initialized' )
								.slick();
						}
					}
					windowWidth = $( window ).width();
				}
			}, 10 );
		} );
	} );
} )( jQuery );
