import { CountUp } from '../node_modules/countup.js/dist/countUp.js';

$(() => {
	const debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
	};
	
	$.each( $( '[data-counterup]' ), ( index, item ) => {
		let dataOptions = item.getAttribute( 'data-options' );
		let counterOptions = JSON.parse( dataOptions );
		let waypoint = new Waypoint( {
			element: item,
			handler: function () {
				const countUp = new CountUp(
					item,
					counterOptions.endVal,
					counterOptions
				);
				if ( ! countUp.error ) {
					countUp.start();
						item.closest('.bs-counter').classList.remove('active');
					const appendClass = debounce(() => {
						item.closest('.bs-counter').classList.add('active');
					}, 20);
					appendClass();
				} else {
					console.error( countUp.error );
				}
			},
			offset: 'bottom-in-view',
		} );
	} );
} );
