$( () => {
	$.each(
		$( '.bs-tab-slider--title-appendto-content' ),
		( index, element ) => {
			const contentArray = [],
				$this = $( element );
			if (
				$this.find( '.bs-slider-tabs' ).find( '.slick-slide' ).length
			) {
				$.each(
					$this
						.find( '.bs-slider-tabs' )
						.find( '.slick-slide:not(.slick-cloned)' ),
					( index, ele ) => {
						$( ele )
							.find( '.slick-slide-wrapper' )
							.children()
							.wrapAll( '<div class="title-contents"></div>' );
						const $content = $( ele ).find( '.title-contents' );
						contentArray.push( $content.clone() );
						$content.append( '<span class="slider-animator"></span>' );
					}
				);
			} else {
				$.each(
					$this
						.find( '.bs-slider-tabs' )
						.find( '.slick-slide-wrapper' ),
					( index, ele ) => {
						$( ele )
							.children()
							.wrapAll( '<div class="title-contents"></div>' );
						const $content = $( ele ).find( '.title-contents' );
						contentArray.push( $content.clone() );
						$content.append( '<span class="slider-animator"></span>' );
					}
				);
			}
			if (
				$this.find( '.bs-slider-content' ).find( '.slick-slide' ).length
			) {
				$.each(
					$this
						.find( '.bs-slider-content' )
						.find( '.slick-slide:not(.slick-cloned)' ),
					( index, ele ) => {
						const $content = $( ele ).find(
							'.slick-slide-wrapper'
						);
						if ( ! $content.find( '.title-content' ).length ) {
							$content.prepend( contentArray[ index ] );
						}
					}
				);
			} else {
				$.each(
					$this
						.find( '.bs-slider-content' )
						.find( '.slick-slide-wrapper' ),
					( index, ele ) => {
						const $content = $( ele );
						if ( ! $content.find( '.title-content' ).length ) {
							$content.prepend( contentArray[ index ] );
						}
					}
				);
			}
		}
	);

	const autoPlaySpeedFunction = () => {
		$( '[data-slick]' ).each( ( index, ele ) => {
			if ( $( ele ).hasClass( 'slick-initialized' ) ) {
				const slick = $( ele ).slick( 'getSlick' );
				if ( slick.options.autoplay === true ) {
					const playSpeed = slick.options.autoplaySpeed;
					$( ele )
						.find( '.slider-animator' )
						.attr( 'data-speed', `${ playSpeed }ms` )
						.css( 'animation-duration', `${ playSpeed }ms` );
				}
			}
		} );
	};

	$( window ).on( 'resize', ( e ) => {
		autoPlaySpeedFunction();
	} );

	autoPlaySpeedFunction();
} );
